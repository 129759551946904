import React, { useEffect, useState, createRef } from 'react';
import * as config from '../../config';
import logo from '../../assets/bmx-logo.png';
import logoIndustry from '../../assets/industry-logo.png';

import VideoPlayer from '../videoPlayer/VideoPlayer';
import SignIn from './SignIn';

import { Grid } from "@mui/material";

import './Chat.css';

const Chat = () => {
  const [showSignIn, setShowSignIn] = useState(false);
  const [username, setUsername] = useState('');
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [connection, setConnection] = useState(null);
  
  const chatRef = createRef();
  const messagesEndRef = createRef();
  
  useEffect(() => {

    const initConnection = async () => {
      const connectionInit = new WebSocket(config.CHAT_WEBSOCKET);
      connectionInit.onopen = (event) => {
        console.log("WebSocket is now open.");
      };
    
      connectionInit.onclose = (event) => {
        console.log("WebSocket is now closed.");
      };
    
      connectionInit.onerror = (event) => {
        console.error("WebSocket error observed:", event);
      };
    
      connectionInit.onmessage = (event) => {
        // append received message from the server to the DOM element 
        const data = event.data.split('::');
        const username = data[0];
        const message = data.slice(1).join('::'); // in case the message contains the separator '::'
    
        const newMessage = {
          timestamp: Date.now(),
          username,
          message
        }
  
        setMessages((prevState) => {
          return [
            ...prevState,
            newMessage
          ];
        });
      };
      setConnection(connectionInit);
    }
    initConnection();
  }, [])

  useEffect(() => {
    const scrollToBottom = () => {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
    }
    scrollToBottom();
  });

  const updateUsername = username => {
    setUsername(username);
    setShowSignIn(false);
    chatRef.current.focus()
  }

  const handleOnClick = () => {
    if (!username) {
      setShowSignIn(true);
    }
  }

  const handleChange = e => {
    setMessage(e.target.value);
  }

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) { // keyCode 13 is carriage return
      if (message) {
        const data = `{
          "action": "sendmessage",
          "data": "${username}::${message.replace(/\\/g, '\\\\').replace(/"/g, '\\"')}"
        }`;
        connection.send(data);
        setMessage('');
      }
    }
  }

  const parseUrls = (userInput) => {
    var urlRegExp = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/g;
    let formattedMessage = userInput.replace(urlRegExp, (match) => {
      let formattedMatch = match;
      if (!match.startsWith('http')) {
        formattedMatch = `http://${match}`;
      }
      return `<a href=${formattedMatch} class="chat-line__link" target="_blank" rel="noopener noreferrer">${match}</a>`;
    });
    return formattedMessage;
  }
  
  const renderMessages = () => {
    return (
      messages.map(msg => {
        let formattedMessage = parseUrls(msg.message);
        return (
          <div className="chat-line" key={msg.timestamp}>
            <p><span className="username">{msg.username}</span><span dangerouslySetInnerHTML={{__html: formattedMessage}} /></p>
          </div>
        )
      })
    )
  }

  return (
    <>
      <header>
        <div className="frame">
          <Grid container
            id="header-container"
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={3}
          >
            <Grid item xs>
              <img className="industry-logo" src={logoIndustry} alt="Logo-industry"/>
            </Grid>
            <Grid item xs={10} alignItems="center" justifyContent="center">
              <h1 class="title-bmx">Welcome to Industry's birthday Live Event !</h1>
            </Grid>
            <Grid item xs>
              <img className="logo" src={logo} alt="Logo"/>
            </Grid>  
          </Grid>
        </div>
      </header>
      <div className="main full-width full-height chat-container">
        <div className="content-wrapper mg-2">
        <VideoPlayer />
          <div className="col-wrapper">
            <div className="chat-wrapper pos-absolute pd-t-1 top-0 bottom-0">
              <div className="messages">
                {renderMessages()}
                <div ref={messagesEndRef} />
              </div>
              <div className="composer">
                <input 
                  ref={chatRef}
                  className={`rounded ${!username ? 'hidden' : ''}`} 
                  type="text" 
                  placeholder="Say something"
                  value={message}
                  maxLength={510}
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                />
                {!username && (
                  <fieldset>
                    <button onClick={handleOnClick} className="btn btn--primary full-width rounded">Click to send messages</button>
                  </fieldset>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row-wrapper">
          For people based in China / Indonesia AND working remotely, please use this <a href="https://teams.microsoft.com/l/meetup-join/19%3ameeting_OGZlMzMxYWYtZDFiOS00N2M4LWIzM2ItNDI3ZDk3N2VmNWIw%40thread.v2/0?context=%7b%22Tid%22%3a%22aec0543a-cc73-4fa4-9e2a-cf52adf9b788%22%2c%22Oid%22%3a%22ef40ac8f-5e8c-43d7-94eb-70e2b8cf196c%22%2c%22IsBroadcastMeeting%22%3atrue%7d&btype=a&role=a">link</a>
        </div>
        {showSignIn && 
          <SignIn updateUsername={updateUsername} />
        }
      </div>
    </>
  )
}

export default Chat;
