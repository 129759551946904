export const amplifyConfig = {
   Auth: {
        region: process.env.REACT_APP_AWS_REGION,
        userPoolId: process.env.REACT_APP_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
        oauth: {
            domain: process.env.REACT_APP_USERPOOL_DOMAIN,
            scope: ['email', 'profile', 'openid'],
            redirectSignIn: window.location.origin,
            redirectSignOut: window.location.origin,
            clientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
            responseType: "code"
        },
    },
    Logger: {
        logLevel: 'ERROR'
    }
};
